import Page from 'components/Page'
import { graphql, PageProps } from 'gatsby'
import type { FC } from 'react'
import React from 'react'
import { Sanity } from 'types/sanity'

type DataProps = {
  sanityPage: Sanity.Page
}

const PageTemplate: FC<PageProps<DataProps>> = ({ data }) => {
  const { description, sections, title } = data.sanityPage

  return (
    <Page
      description={description}
      sections={sections as Sanity.PageSections}
      title={title}
    />
  )
}

export default PageTemplate

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    sanityPage(id: { eq: $id }) {
      ...SanityPageData
    }
  }
`
